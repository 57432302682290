import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import Cookies from "js-cookie";
import AuthContext from "../contexts/AuthContext";
import { initGA } from "../helpers/utils";
import { ClarityId, GoogleAnalyticsId } from "../config/config";
import { clarity } from "react-microsoft-clarity";
import dynamic from "next/dynamic";

const Switch = dynamic(() => import("react-switch"), { ssr: false });

class CookiePopup extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      cookieVal: this.props.cookieVal !== undefined ? this.props.cookieVal : false,
      showCookieModal: false,
      analyticsCookies: true,
      isWidget: this.props.route === "/widget/[eventUrl]" || this.props.route === "/widget/single/[eventUrl]",
      isEventOrCheckout: ["/[eventUrl]", "/checkout/[eventUrl]", "/ticket-confirmation"].includes(this.props.route),
      showCookieBox: false,
    };
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      if (Cookies.get("Cookie") !== undefined) this.setState({ cookieVal: true });
      if (Cookies.get("AnalyticsCookies") === "Accept") this.handleAcceptCookie();
      setTimeout(() => this.setState({ showCookieBox: true }), 2000);

      if (Cookies.get("Cookie") === undefined && Cookies.get("AnalyticsCookies") === undefined && this.state.isEventOrCheckout) {
        Cookies.set("ShouldDeleteCookie", "true", { expires: 800, sameSite: "strict" });
        this.handleAcceptCookie();
      } else if (Cookies.get("ShouldDeleteCookie") === "true") {
        this.handleDeclineCookie();
      }
    }
  }

  handleAcceptCookie = () => {
    initGA(GoogleAnalyticsId);
    clarity.init(ClarityId);
    clarity.consent();
  };

  handleDeclineCookie = () => {
    if (window.ga) window.ga("remove");
    Cookies.remove("_ga", { path: "/", domain: document.domain });
    Cookies.remove("_gid", { path: "/", domain: document.domain });
    Cookies.remove("_gat", { path: "/", domain: document.domain });
    if (Cookies.get("ShouldDeleteCookie") === "true") {
      Cookies.remove("ShouldDeleteCookie", { path: "/", domain: document.domain });
    }
  };

  handleSave = () => {
    Cookies.set("AnalyticsCookies", this.state.analyticsCookies ? "Accept" : "Declined", { expires: 800, sameSite: "strict" });
    Cookies.set("Cookie", "Accept", { expires: 800, sameSite: "strict" });
    if (this.state.analyticsCookies) {
      this.handleAcceptCookie();
    } else {
      this.handleDeclineCookie();
    }
    this.setState({ showCookieModal: false, cookieVal: true });
    this.context.acceptCookie();
  };

  render() {
    return (
      <>
        {!this.state.cookieVal && !this.state.isWidget && !this.state.isEventOrCheckout && this.state.showCookieBox && (
          <div className="cookie-banner cookie-not-con shadow-lg p-4 rounded font-figtree">
            <div className="d-flex flex-column gap-4 icon-container align-items-start">
              <div className="d-flex align-items-center">
                <img
                  src="https://d2nnrpx7gk2my2.cloudfront.net/files/671f594f336e080008337bd6/cookie.svg"
                  alt="Cookie Icon"
                  className="cookie-icon me-3"
                />
                <p className="cookie-title">About Cookies on this site</p>
              </div>
              <div className="flex-grow-1">
                <p className="cookie-text">
                  We use cookies to collect and analyse information on site performance and usage, to
                  provide social media features and to enhance and customise content and your user
                  experience.{" "}
                  <a href="/cookie-policy" target="_blank" className="learn-more-link">
                    Learn more
                  </a>
                </p>
              </div>
              <div className="d-flex w-100 justify-content-end mt-3">
                <button
                  className="btn btn-primary me-2 custom-button"
                  onClick={() => this.setState({ showCookieModal: true })}
                >
                  Settings
                </button>
                <button
                  onClick={() => {
                    Cookies.set("Cookie", "Accept", { expires: 800, sameSite: "strict" });
                    Cookies.set("AnalyticsCookies", "Accept", { expires: 800, sameSite: "strict" });
                    this.handleAcceptCookie();
                    this.setState({ cookieVal: true });
                    this.context.acceptCookie();
                  }}
                  className="btn btn-outline-primary custom-button"
                >
                  Allow All Cookies
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Modal */}
        <Modal
          show={this.state.showCookieModal}
          onHide={() => this.setState({ showCookieModal: false })}
          centered
          className="cookie-settings-modal font-figtree"
        >
          <Modal.Header style={{ border: "0" }} closeButton className="no-border-header modal-header">
            <Modal.Title className="settings-title">Advanced Cookie Settings</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="cookie-setting-item d-flex flex-column align-items-start">
              <h6 className="cookie-subtitle">Essential Cookies</h6>
              <div className="d-flex justify-content-between align-items-start">
                <p className="cookie-description">
                  These cookies enable core functionality such as security, verification of identity,
                  and network management. These cookies can’t be disabled.
                </p>
                <span>
                  <Switch
                    name="essential_cookies"
                    height={30}
                    width={55}
                    offColor="#DDDDDD"
                    onColor="#002E6E"
                    handleDiameter={23}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    checked={true}
                    onChange={() => { }}
                    disabled={true}
                  />
                </span>
              </div>
            </div>
            <div className="cookie-setting-item d-flex flex-column align-items-start">
              <h6 className="cookie-subtitle">Enable Analytics Cookies</h6>
              <div className="d-flex justify-content-between align-items-start">
                <p className="cookie-description">
                  These cookies help us to understand how visitors interact with our website,
                  discover errors, and provide better overall analytics.
                </p>
                <div>
                  <Switch
                    name="analytics_cookies"
                    height={30}
                    width={55}
                    offColor="#DDDDDD"
                    onColor="#002E6E"
                    handleDiameter={23}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    checked={this.state.analyticsCookies}
                    onChange={() => {
                      this.setState({
                        ...this.state,
                        analyticsCookies:
                          !this.state.analyticsCookies
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0 px-2 py-3">
            <button className="save-button" onClick={this.handleSave}>
              Save
            </button>
          </Modal.Footer>
        </Modal>

        <style jsx>{`
          .cookie-banner {
            max-width: 645px;
            background-color: white;
            border-radius: 5px;
            padding: 40px 30px !important;
            box-shadow: 0px 0px 21.2px 0px #012e6f4d !important;
          }
          .cookie-icon {
            width: 40px;
            height: 40px;
          }
          .cookie-not-con {
              position: fixed;
              bottom: 20px;
              left: 15px;
              z-index: 1;
              box-sizing: border-box;
              width: 100%;
              background-color: #ffffff;
              box-shadow: 0px 0px 10px #00000026;
            }

            .cookie-not-con p {
              margin-bottom: 0px;
            }
          .modal-header {
            border: 0 !important;
          }
          .cookie-title {
            font-weight: 600;
            font-size: 20px;
            color: #002e6e;
          }
          .cookie-text {
            color: #424242;
            font-size: 16px;
          }
          .learn-more-link{
            text-decoration:none;
          }
          .custom-button {
            font-weight: 700;
            border-radius: 5px;
            font-size: 16px;
            padding: 5px 30px !important;
          }
          .btn-primary {
            background-color: #002e6e;
            border-color: #fff;
          }
          .btn-outline-primary {
            color: #002e6e;
            border: 1px solid #002e6e;
          }
          .btn-outline-primary:hover {
            background-color: #fff;
          }
          .cookie-settings-modal .modal-content {
            border: none;
            border-radius: 8px;
          }
          .settings-title {
            font-weight: 600;
            color: #002e6e;
            font-size: 20px;
          }
          .cookie-setting-item {
            margin-bottom: 20px;
          }
          .cookie-subtitle {
            color: #002e6e;
            font-size: 18px;
          }
          .cookie-description {
            color: #424242;
            font-size: 16px;
          }
          .toggle-checkbox {
            width: 40px;
            height: 20px;
            appearance: none;
            background-color: #ccc;
            border-radius: 10px;
            cursor: pointer;
            transition: background-color 0.3s ease;
          }
          .toggle-checkbox:checked {
            background-color: #003366;
          }
          .toggle-checkbox:checked::before {
            transform: translateX(20px);
          }
          .toggle-checkbox::before {
            content: "";
            position: absolute;
            top: 2px;
            left: 2px;
            width: 16px;
            height: 16px;
            background-color: #fff;
            border-radius: 50%;
            transition: transform 0.3s ease;
          }
          .save-button {
            font-weight: 700;
            border-radius: 5px;
            font-size: 16px;
            padding: 5px 30px !important;
            background-color: #002e6e;
            color: #fff;
          }
        `}</style>
      </>
    );
  }
}

export default CookiePopup;